import React from 'react'
import { Link } from 'gatsby'
import { rhythm, scale } from '../utils/typography'
import 'bootstrap/dist/css/bootstrap.css'
import Header from './Header'
import './styles.css'
import './Contact-Form-Clean.css'
import 'font-awesome/css/font-awesome.css'
import './Footer-Basic.css'

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    return (
      <div>
        <Header />
        <div>
          {header}
          {children}{' '}
          <footer class="footer-basic">
            <div class="social">
              <a href="#">
                <i class="icon ion-social-instagram" />
              </a>
              <a href="#">
                <i class="icon ion-social-twitter" />
              </a>
              <a href="#">
                <i class="icon ion-social-facebook" />
              </a>
            </div>
            <ul class="list-inline">
              <li class="list-inline-item">
                <a href="#">Home</a>
              </li>
              <li class="list-inline-item">
                <a href="#services">Appraisals</a>
              </li>
              <li class="list-inline-item">
                <a href="#about">About</a>
              </li>
              <li class="list-inline-item">
                <a href="#contactarea">Contact</a>
              </li>
            </ul>
            <p className="copyright">
              Ute Valley Appraisals © {new Date().getFullYear()}, Designed and
              Powered by
              {` `}
              <a href="https://webriq.com">WebriQ</a>
            </p>
          </footer>
        </div>
      </div>
    )
  }
}

export default Layout
