import React from 'react'
import { Link } from 'gatsby'
import { Nav, Dropdown, NavItem } from 'react-bootstrap'
import './updated-side-menu-1.css'
import './updated-side-menu.css'
import Logo from '../img/utevalley-logo.png'

export default () => (
  <div className="menu-container py-3">
    <div class="container">
      <div class="nav-brand">
        <img class="img-fluid logo sidebar" src={Logo} />
      </div>

      <div class="nav_content">
        <Nav className="flex-column">
          <Nav.Item>
            <Link to="/">Home</Link>
          </Nav.Item>
          <Nav.Item>
            <a href="#services">Appraisals</a>
          </Nav.Item>
          <Nav.Item>
            <a href="#about">About</a>
          </Nav.Item>
          <Nav.Item>
            <a href="#contactarea">Contact</a>
          </Nav.Item>
        </Nav>
      </div>
    </div>
  </div>
)
