import React from 'react'
import SideBar from 'react-sidebar'
import { Motion, spring, presets } from 'react-motion'
import Menu from './Menu'
import Bars from '../img/bars.svg'
import './updated-side-menu.css'
import './updated-side-menu-1.css'

class SideBarMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      sidebarpullRight: true,
      toggle: false,
    }

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
  }
  onSetSidebarOpen(state) {
    this.setState({ sidebarOpen: state.open, toggle: !this.state.toggle })
  }
  toggleMenu() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
      toggle: !this.state.toggle,
    })
  }
  render() {
    const style = {
      overflow: 'visible',
      cursor: 'pointer',
      // disable touch highlighting on devices
      WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    }
    return (
      <SideBar
        sidebar={
          <Menu /> // Menu list
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight={this.state.sidebarpullRight}
        styles={{
          sidebar: {
            background: 'white',
            width: '300px',
            position: 'fixed',
            zIndex: '3',
          },
          root: { position: 'relative', overflow: 'visible' },
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.65)', zIndex: 2 },
          content: {
            position: 'relative',
            overflow: 'visible',
            cursor: 'pointer',
          },
        }}
      >
        <h2
          class="float-right nav_handle mb-0"
          onClick={() => this.toggleMenu()}
        >
          {' '}
          <img src={Bars} />
        </h2>
      </SideBar>
    )
  }
}

export default SideBarMenu
