import React from 'react'
import { Link } from 'gatsby'
import Sticky from 'react-sticky-el'
import SideBarMenu from './Sidebar'
import styl from './header.module.css'
import './updated-side-menu.css'
import './updated-side-menu-1.css'
import Logo from '../img/utevalley-logo.png'
import Bars from '../img/bars.svg'

const Header = () => (
    <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: 'none', zIndex: '2' }}
    >
      <div class={styl.menuContainer}>
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-9 col-md-4">
              <div class="nav-brand">
                <a href="/"><img class="img-fluid logo" src={Logo} /></a>
              </div>
            </div>
            <div class="col-2 col-md-8 ml-auto">
              <div class="nav_content" />
              <div
                class="float-right navigation"
                data-navigation-handle=".nav_handle"
                data-navigation-content=".nav_content"
              >
                <SideBarMenu />
              </div>
              <div class="nav-right">
                <div class="cta text-left align-self-center">
                  <a
                    class="btn btn-primary atf appraisal nav-num"
                    href="tel:719-257-3302"
                  >
                    <i class="fa fa-phone" aria-hidden="true" /> 719-257-3302
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header